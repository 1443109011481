import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom'; // eslint-disable-line no-unused-vars 
import styled from 'styled-components';

// header footer
import Footer from '../footer';
import Loading from '../shared/loading';
import { fadeIn } from '../shared/styles/animation';

const Home = React.lazy(() => import('./home'));
const Dashboard = React.lazy(() => import('./dashboard'));
const Activity = React.lazy(() => import('./activity'));
const Search = React.lazy(() => import('./search'));
const CreateWorkOrder = React.lazy(() => import('./create-work-order'));
const ManageProducts = React.lazy(() => import('./products'));
const ViewWorkOrder = React.lazy(() => import('./view-work-order'));
const Files = React.lazy(() => import('./files'));

const Routes = ({ location, version }) => (
  <>
    <Suspense fallback={<Loading />}>
      <Content>
        <Route exact path="/" component={Home} />
        <Route exact path="/create-work-order/" component={CreateWorkOrder} />
        <Route exact path="/products/" component={ManageProducts} />
        <Route path="/dashboard/" component={Dashboard} />
        <Route exact path="/activity/" component={Activity} />
        <Route exact path="/search/" component={Search} />
        <Route path="/work-order/" component={ViewWorkOrder} />
        <Route path="/files/" component={Files} />
        <Version>
          <span>{version}</span>
        </Version>
      </Content>
    </Suspense>
    <Footer
      current={location.pathname}
    />
  </>
);

export default withRouter(Routes);

Routes.defaultProps = {
  location: null,
};

Routes.propTypes = {
  location: PropTypes.objectOf(PropTypes.shape),
  version: PropTypes.string.isRequired,
};

const Content = styled.section`
  padding: 1em 1em 6em;
  max-width: ${(props) => props.theme.mq.max};
  margin: 0 auto;
  opacity: 0;
  animation: .2s .1s ${fadeIn} ease-out forwards;
  @media (min-width: ${(props) => props.theme.mq.medium}) {
    padding: 0 2em 6em;
}`;

const Version = styled.div`
  text-align: right;
  span {
    display: inline-block;
    opacity: .15;
    font-size: .8125em;
    letter-spacing: .1em;
    margin: 1em 0 0;
    padding: 1em 0;
    transition: opacity .3s ease;
    &:hover {
      opacity: .8;
    }
  }
`;
