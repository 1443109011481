import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { mix } from 'polished';

import { spin, throb } from './styles/animation';

const Loading = ({ msg, note }) => (
  <LoadingOuter>
    <LoadingSpinner />
      <LoadingMSG>
        {msg && (
          msg
        )}
        {!msg && (
          `Loading`
        )}
        &#8230;
      </LoadingMSG>
      {note && (
        <LoadingNote>
          {note}
        </LoadingNote>
      )}
  </LoadingOuter>
);

export default Loading;

Loading.defaultProps = {
  msg: null,
};

Loading.propTypes = {
  msg: PropTypes.string,
};

const LoadingOuter = styled.div`
  opacity: 0;
  animation: 1.4s ${throb} infinite linear;
`;

const LoadingSpinner = styled.div`
  margin: 8em auto 0;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background: ${(props) => props.theme.colours.background} linear-gradient(to right, ${(props) => props.theme.colours.secondary} 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: ${spin} .7s infinite linear;
  transform: translateZ(0);
  &:before {
    width: 50%;
    height: 50%;
    background: ${(props) => props.theme.colours.background};
    border-radius: 100% 0 0 0;
    position: absolute;
    top:  -1px;
    left: -1px;
    content: '';
  }
  &:after {
    background: ${(props) => props.theme.colours.background};
    width: 80%;
    height: 80%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const LoadingMSG = styled.p`
  margin: 2em 0 0;
  font-size: .875em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .3em;
  color: ${(props) => props.theme.colours.secondary && mix(0.3, '#fff', props.theme.colours.secondary)};
`;

const LoadingNote = styled.p`
  margin: 1em 0 0;
  font-size: .875em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .1em;
  color: ${(props) => props.theme.colours.secondary && mix(0.3, '#fff', props.theme.colours.secondary)};
`;
