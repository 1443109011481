import React, { useState, Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { AzureAD, AuthenticationState } from 'react-aad-msal';
import REACT_APP_VAS_API_URL from './components/auth/APIURL';
import authProvider from './components/auth/authProvider';

// state/context
import { UserProvider } from './components/state/userContext';

// grab basic theme styles
import theme from './theme';
import Routes from './components/routes';
import Loading from './components/shared/loading';

import Header from './components/header/index';
import LoginWelcome from './components/auth/LoginWelcome';

const gitVersion = require('./gitVersion.txt');

const VASApp = () => {
  const [user, setUser] = useState(null);
  const [version, setVersion] = useState(null);

  const updateUserInfo = async (accountInfo, accessToken) => {
    try {
      // Perform the fetch request
      const res = await fetch(`${REACT_APP_VAS_API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      });
  
      // Check if the response is OK
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Parse the JSON response
      const responseJson = await res.json();
  
      // Check if data is not empty and set the user state
      if (responseJson) {
        setUser({ VasApi: responseJson });
      } else {
        throw new Error('Empty response');
      }
    } catch (error) {
      // Handle errors
      console.error('Error fetching user info:', error);
    }
  };
  useEffect(() => {
    fetch(gitVersion).then((result) => {
      return result.text();
    }).then((versionNumber) => {
      setVersion(`v1.1.${versionNumber}`);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AzureAD
        provider={authProvider}
      >
        {
          ({ login, logout, authenticationState, error, accountInfo }) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                if (!accountInfo.jwtAccessToken) {
                  authProvider.getAccessToken().then((res) => updateUserInfo(accountInfo, res.accessToken));
                }
                return (
                  <>
                    <Suspense fallback={<Loading />}>
                      <UserProvider value={user}>
                        <Router>
                          <Header logout={logout} />
                          {user && (
                            <Routes user={user} version={version} />
                          )}
                          {!user && (
                            <Loading msg="Getting User Data" />
                          )}
                        </Router>
                      </UserProvider>
                    </Suspense>
                  </>
                );
              case AuthenticationState.Unauthenticated:
                return (
                  <>
                    {error && <p>An error occurred during authentication, please try again!</p>}
                    <LoginWelcome login={login} version={version} />
                  </>
                );
              case AuthenticationState.InProgress:
                return (<Loading msg="Authenticating" />);
              default:
                break;
            }
          }
        }
      </AzureAD>
    </ThemeProvider>
  );
};

export default VASApp;
