/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link } from 'react-router-dom'; // Having issues with NavLink, so pass in current location and do manually
import styled from 'styled-components';

import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as CreateIcon } from '../../assets/svg/create.svg';
// import { ReactComponent as ProceduresIcon } from '../../assets/svg/procedures.svg';
// import { ReactComponent as StatsIcon } from '../../assets/svg/stats.svg';
import { ReactComponent as ListIcon } from '../../assets/svg/list.svg';
import { ReactComponent as UpTrendArrowIcon } from '../../assets/svg/up-trend-arrow.svg';
import { ReactComponent as ProductsIcon } from '../../assets/svg/product.svg';

const Footer = ({ current }) => (
  <>
    <Nav>
      <NavList>
        <NavListItem>
          <Link
            to="/"
            className={current === '/' ? 'selected' : null}
          >
            <NavIcon>
              <HomeIcon />
            </NavIcon>
            {'Home'}
          </Link>
        </NavListItem>

        <NavListItem>
          <Link
            to="/create-work-order/"
            className={current === '/create-work-order/' ? 'selected' : null}
          >
            <NavIcon>
              <CreateIcon />
            </NavIcon>
            {'New'}
          </Link>
        </NavListItem> 

        <NavListItem>
          <Link
            to="/dashboard/"
            className={current === '/dashboard/' ? 'selected' : null}
          >
            <NavIcon>
              <ListIcon />
            </NavIcon>
            {'View'}
          </Link>
        </NavListItem>

        {/* <NavListItem>
          <Link
            to="/products/"
            className={current === '/products/' ? 'selected' : null}
          >
            <NavIcon>
              <ProductsIcon />
            </NavIcon>
            {'Prods'}
          </Link>
        </NavListItem> */}

        <NavListItem>
          <Link
            to="/activity/"
            className={current === '/activity/' ? 'selected' : null}
          >
            <NavIcon>
              <UpTrendArrowIcon />
            </NavIcon>
            {'Activity'}
          </Link>
        </NavListItem>
      </NavList>
    </Nav>
  </>
);

export default Footer;

const Nav = styled.nav`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0,0,0,.933);
  user-select: none;
  transition: opacity .6s  ease;
  z-index: 10;
  body.showModal & {
    opacity: 0;
    pointer-events: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
`;

const NavIcon = styled.div`
  text-align: center;
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin: 0 auto .25em;
  svg {
    width: 20px;
    height: 20px;
    fill: #555;
    transition: fill .3s ease;
  }
`;

const NavListItem = styled.li`
  position: relative;
  //margin: 0 .25em;
  font-size: .625em;
  line-height: 1.4;
  transition: color .3s ease;
  text-transform: uppercase;
  letter-spacing: .15em;
  &::after {
    position: absolute;
    top: 50%;
    right: -1px;
    content: '';
    width: 1px;
    height: 60%;
    background: rgba(255,255,255,.15);
    transform: translateY(-50%);
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
  a {
    display: block;
    color: #a3a3a3;
    text-decoration: none;
    padding: 1em calc(.25em + 4vw);
    &.selected {
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
`;
