/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

const SimpleButton = props => (
  <>
    {props.link && (
      <Link to={props.link}>
        <Button {...props} title={props.description}>
          {props.children}
        </Button>
      </Link>
    )}
    {!props.link && (
      <Button {...props}>
        {props.children}

        {props.description && props.unmetRequirements.length === 0 && (
          <ButtonDesc>{props.description}</ButtonDesc>
        )}

        {props.unmetRequirements && props.unmetRequirements.length > 0 && (
          <UnmetReq className="reset-list">
            {props.unmetRequirements.map((req, index) => <UnmetReqItem key={index}>{req}</UnmetReqItem>)}
          </UnmetReq>
        )}

      </Button>
    )}
    {/*props.description && (
      <ButtonDesc>{props.description}</ButtonDesc>
    )*/}

 
  </>
);

export default SimpleButton;

SimpleButton.defaultProps = {
  description: null,
  link: null,
  type: 'button',
  disabled: false,
  unmetRequirements: null,
};

SimpleButton.propTypes = {
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  unmetRequirements: PropTypes.arrayOf(PropTypes.string),
};

const Button = styled.button`
  /* background-color: #555;
  border: 2px solid #555;
  border-radius: .75em 0;
  margin: 0;
  padding: .95em 1.75em .85em; 
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color .6s ease, color .6s ease;
  text-transform: uppercase;
  letter-spacing: .033em;
  line-height: 1.3;
  font-size: 1rem;
  color: #fff;
  transition: opacity .3s ease;
  box-shadow: 0 .5em 1em rgba(0,0,0,.1);*/

  position: relative;
  background: none;
  border: none;
  color: #fff;
  font-size: .8em;
  letter-spacing: .1em;
  padding: .875em 2em .825em;
  font-weight: normal;
  cursor: pointer;
  text-transform: uppercase;
  background: #444;
  border-left: .25em solid transparent;
  border-right: .25em solid transparent;
  transition: background-color .6s ease, transform .3s ease;
  &:hover{
    transform: scale(1.05);
  }
  ${(props) => props.polarity
  && css`
    border-color: ${props.theme.colours[props.polarity]};
    background-color: ${props.theme.colours[props.polarity]};
    background-color: ${rgba(props.theme.colours[props.polarity], 0.3)};
    &:hover{
      box-shadow: 0 0 .5em rgba(0,0,0,.4);
      background-color: ${rgba(props.theme.colours[props.polarity], 0.5)};
    }
  `}


  
  &:hover {
    opacity: .8;
  }
  /* Sizes */
  ${props => props.micro
    && css`
      padding: .65em .6em .45em;
      font-size: .75rem;
      border-radius: .5em 0;
    `
  }
  ${props => props.tiny
    && css`
      padding-left: 1em;
      padding-right: 1em;
      font-size: .75rem;
    `
  }
  ${props => props.small
    && css`
      font-size: .75rem;
      @media (min-width: ${(props) => props.theme.mq.large}) {
        font-size: .875rem;
      }
    `
}
  ${props => props.large
    && css`
      font-size: 1.5rem;
    `
}

  /* status */
  ${props => props.polarity
    && css`
      //border-color: ${props.theme.colours[props.polarity]};
      background-color: ${props.theme.colours[props.polarity]};
      background-color: ${rgba(props.theme.colours[props.polarity], 0.2)};
      //transition: background-color .6s ease, transform .3s ease;
      &:hover{
        opacity: 1;
        transform: scale(1.075);
        box-shadow: 0 0 1em rgba(0,0,0,1);
        //background-color: ${rgba(props.theme.colours[props.polarity], 1)};
      }
    `
  }
  ${props => props.disabled
    && css`
      opacity: .3;
      filter: grayscale(.9);
      pointer-events: none;
      cursor: not-allowed;
      &:hover {
        opacity: .15;
      }
    `
  }
  /* Primary theme colour */
  ${props => props.primary
    && css`
      //background-color: ${props => props.theme.colours.primary};
      background-color: ${rgba(props.theme.colours.primary, 0.3)};
      border-color: ${props => props.theme.colours.primary};
      &:hover{
        background-color: ${rgba(props.theme.colours.primary, 0.5)};
      }
    `
  }
    ${props => props.primaryWhite
    && css`
      color: ${props => props.theme.colours.primary};
      background-color: #fff;
      border-color: #fff;
    `
  }
  /* Secondary theme colour */
  ${props => props.secondary
    && css`
      background-color: ${props => props.theme.colours.secondary};
      border-color: ${props => props.theme.colours.secondary};
      ${props.outline
        && css`
          background: transparent;
          color: ${props => props.theme.colours.secondary};
        `
      };
    `
  }
  /* Alert */
  ${props => props.alert
    && css`
      background-color: ${props => props.theme.colours.alert};
      border-color: ${props => props.theme.colours.alert};
      ${props.outline
        && css`
          background: transparent;
          color: ${props => props.theme.colours.alert};
        `
};
    `
}
  /* Custom Background colour*/
  ${props => props.customBGColour
    && css`
      //background-color: ${props.customBGColour};
      border-color: ${props.customBGColour};
      background-color: ${rgba(props.customBGColour, 0.3)};
      &:hover{
        background-color: ${rgba(props.customBGColour, 0.5)};
      }
      ${props.outline
        && css`
          background: transparent;
        `
};
    `
}
  /* Custom Foreground colour */
  ${props => props.customFGColour
    && css`
      color: ${props.customFGColour};
    `
  }
`;

const ButtonsMsgStyle = css`
  text-transform: none;
  padding-top: .75em;
  margin-top: .75em;
  font-size: .875em;
  font-weight: normal;
  letter-spacing: 0;
  border-top: 1px solid rgba(255,255,255,.2);
  line-height: 1.4;
`

const ButtonDesc = styled.div`
  ${ButtonsMsgStyle}
`;

const UnmetReq = styled.ul`
  ${ButtonsMsgStyle}
`;

const UnmetReqItem = styled.li`

`;
