/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import UserContext from '../state/userContext';

import MenuIcon from './menuIcon';
import SearchIcon from './searchIcon';
import Nav from './nav';

const Header = ({ logout }) => {
  const user = useContext(UserContext);

  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <>
      <SiteHeader>
        <MenuIcon toggleNav={user ? toggleNav : null} showNav={showNav} />
        <Title>
          <Link to="/">VAS Manager</Link>
        </Title>
        <SearchIcon />
      </SiteHeader>
      {user && (
        <Nav toggleNav={toggleNav} showNav={showNav} logout={logout} />
      )}
    </>
  );
};

export default Header;

const SiteHeader = styled.header`
  display: flex;
  align-items: center;
  background: linear-gradient(to left, #23a894 0%,#4b4093 100%);
`;

const Title = styled.h1`
  font-size: 1.125em;
  font-weight: 300;
  flex-grow: 1;
  margin: 0 0 0 1.5rem;
  user-select: none;
  a {
    display: inline-block;
    padding: .5em 0;
    color: #fff;
    text-decoration: none;
  }
`;
