import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SimpleButton from '../shared/buttons/simpleButton';
import { ReactComponent as Logo } from '../../assets/svg/brandpath-logo.svg';

const LoginWelcome = ({ login, version }) => {
  document.title = 'VAS / Login';

  return (
    <>
      <Login>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <LoginBox>
          <LoginBoxShape>
            <LoginTitle>VAS Manager</LoginTitle>
            <div>
              <SimpleButton type="submit" onClick={login} primary>sign in with Azure AD</SimpleButton>
            </div>
          </LoginBoxShape>
        </LoginBox>
        {version && (
          <Version>{version}</Version>
        )}
      </Login>
    </>
  )
};

export default LoginWelcome;

LoginWelcome.defaultProps = {
  version: null,
};

LoginWelcome.propTypes = {
  login: PropTypes.func.isRequired,
  version: PropTypes.string,
};

const Login = styled.section`
  padding: 0 2em;
`;

const LogoContainer = styled.div`
  width: 66%;
  max-width: 24em;
  margin: calc(2em + 1vw) auto;
  opacity: .15;
  transition: opacity 8s ease;
  &:hover {
    opacity: 1;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const LoginBox = styled.section`
  position: relative;
  text-align: center;
  max-width: 24em;
  margin: 0 auto;
  filter: 
    drop-shadow(-4px 0 0 rgba(42,165,145, 1)) 
    drop-shadow(4px 0 0 rgba(42,165,145, 1)) 
    drop-shadow(0 0 1.25em rgba(42,165,145,.275));
`;

const LoginBoxShape = styled.div`
  padding: 2em;
  background: #000;
  background-image: linear-gradient(to bottom, rgba(13,13,13,1) 0%, #232130 80%); 
  clip-path: polygon(101% 89%, 96% 101%, -1% 101%, -1% 11%, 4% -1%, 101% -1%);
  will-change: transform;
`;

const LoginTitle = styled.h1`
  margin: 0 0 1em;
  font-weight: 100;
  font-size: calc(2em + .5vw);
  line-height: 1.2;
`;

const Version = styled.div`
  text-align: right;
  opacity: .15;
  font-size: .8125em;
  letter-spacing: .1em;
  margin: 1em 0 0;
  padding: 1em 0;
  &:hover {
    opacity: .8;
  }
`;

