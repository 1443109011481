/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as CreateIcon } from '../../assets/svg/create.svg';
//import { ReactComponent as StatsIcon } from '../../assets/svg/stats.svg';
import { ReactComponent as ListIcon } from '../../assets/svg/list.svg';
// import { ReactComponent as ProceduresIcon } from '../../assets/svg/procedures.svg';
import { ReactComponent as UpTrendArrowIcon } from '../../assets/svg/up-trend-arrow.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { ReactComponent as ProductIcon } from '../../assets/svg/product.svg';
import { ReactComponent as DownloadIcon } from '../../assets/svg/download.svg';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout.svg';



const MenuIcon = ({showNav, toggleNav, logout }) => (
  <NavScrim showNav={showNav} onClick={() => toggleNav()}>
    <Nav onClick={(e) => e.stopPropagation()} showNav={showNav}>
      <Close onClick={() => toggleNav()}>
        <CloseIcon />
      </Close>
      <NavUL className="reset-list">
        <NavLI>
          <NavLink to="/" exact activeClassName="selected" onClick={() => toggleNav()}>
            <HomeIcon />
            <NavLabel>Home</NavLabel>
          </NavLink>
        </NavLI>

        <NavLI>
          <NavLink to="/create-work-order/" activeClassName="selected" onClick={() => toggleNav()}>
            <CreateIcon />
            <NavLabel>New Work Order</NavLabel>
          </NavLink>
        </NavLI>

        <NavLI>
          <NavLink to="/dashboard/" activeClassName="selected" onClick={() => toggleNav()}>
            <ListIcon />
            <NavLabel>View Work Orders</NavLabel>
          </NavLink>
        </NavLI>

        {/* <NavLI>
          <NavLink to="/products/" activeClassName="selected" onClick={() => toggleNav()}>
            <ProductIcon />
            <NavLabel>Manage Products</NavLabel>
          </NavLink>
        </NavLI> */}

        {/* <NavLI>
          <NavLink to="/procedures/" activeClassName="selected" onClick={() => toggleNav()}>
            <ProceduresIcon />
            <NavLabel>Work Procedures</NavLabel>
          </NavLink>
        </NavLI>

        <NavLI>
          <NavLink to="/stats/" activeClassName="selected" onClick={() => toggleNav()}>
            <StatsIcon />
            <NavLabel>Statistics</NavLabel>
          </NavLink>
        </NavLI> */}

        <NavLI>
          <NavLink to="/search/" activeClassName="selected" onClick={() => toggleNav()}>
            <SearchIcon />
            <NavLabel>Search</NavLabel>
          </NavLink>
        </NavLI>

        <NavLI>
          <NavLink to="/activity/" activeClassName="selected" onClick={() => toggleNav()}>
            <UpTrendArrowIcon />
            <NavLabel>Activity Stream</NavLabel>
          </NavLink>
        </NavLI>

        <NavLI>
          <NavLink to="/files/" activeClassName="selected" onClick={() => toggleNav()}>
            <DownloadIcon />
            <NavLabel>Files</NavLabel>
          </NavLink>
        </NavLI>

        <NavLI>
          <NavLink to="/" activeClassName="selected" exact onClick={() => logout()}>
            <LogoutIcon />
            <NavLabel>Log out</NavLabel>
          </NavLink>
        </NavLI>
      </NavUL>
    </Nav>
  </NavScrim>
);

export default MenuIcon;

MenuIcon.propTypes = {
  showNav: PropTypes.bool.isRequired,
  toggleNav: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const NavScrim = styled.div`
  background-color: transparent;
  transition: background-color .4s ease;
  ${(props) => props.showNav
    && css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,.66);
      z-index: 10;
    `
  }
`;

const Nav = styled.nav`
  position: absolute;
  width: 75%;
  top: 0;
  bottom: 0;
  background: ${(props) => props.theme.colours.secondary};
  background-image: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,.1) 100%);
  transform: translateX(-100%);
  transition: transform .25s ease, opacity .4s ease;
  z-index: 10;
  box-shadow: 0 0 2em rgba(0,0,0,1);
  opacity: 0;
 
  @media (min-width: ${(props) => props.theme.mq.medium}) {
    max-width: 28rem;
  }
  ${(props) => props.showNav
    && css`
       transform: translateX(0);
       opacity: 1;
    `
  }
`;

const NavUL = styled.ul`
  border-top: 1px solid rgba(255,255,255,.3);
  margin: 4em 1em 0;
`;

const NavLI = styled.li`
  border-bottom: 1px solid rgba(255,255,255,.3);
  a {
    display: flex;
    align-items: center;
    padding: 1em;
    text-decoration: none;
    transition: background-color .3s ease, color .3s ease, padding .45s ease;
    &:hover {
      background: #fff;
      color: ${(props) => props.theme.colours.secondary};
      padding-left: 1.5em;
      svg {
         fill: ${(props) => props.theme.colours.secondary};
      }
    }
    &.selected {
      border-left: .25em solid #fff;
    }
  }
  svg {
    width: 1.5em;
    height: 1.5em;
    fill: #fff;
    transition: fill .3s ease;
  }
`;

const NavLabel = styled.span`
  display: block;
  margin-left: .75em;
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.125rem;
  fill: #fff;
  cursor: pointer;
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

